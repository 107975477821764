// Generic types
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Meditation types
export const GET_MEDITATIONS = "GET_MEDITATIONS";
export const GET_RANDOM_MEDITATION = "GET_RANDOM_MEDITATION";
export const ADD_MEDITATION = "ADD_MEDITATION";
export const DELETE_MEDITATION = "DELETE_MEDITATION";

export const FILTER_MEDITATIONS = "FILTER_MEDITATION";
export const CLEAR_MEDITATIONS = "CLEAR_MEDITATIONS";
export const MEDITATION_ERROR = "MEDITATION_ERROR";

// meditation card types
export const GET_CARDTYPES = "GET_CARDTYPES";

// Transaction types
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_LAST_TRANSACTIONS = "GET_LAST_TRANSACTIONS";
export const GET_LAST_TRANSACTIONS_UID = "GET_LAST_TRANSACTIONS_UID";
export const TRANSACTION_ERROR = "MEDITATION_ERROR";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const DELETE_TRANSACTION = "DELETE_MEDITATION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const GET_TRANSACTION_TYPES = "GET_TRANSACTION_TYPES";
export const GET_CONTEXTS = "GET_CONTEXTS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_IS_LOADING = "SET_IS_LOADING";
