import React, { Fragment, useContext, useEffect } from "react";
import TransactionContext from "../../context/transaction/transactionContext";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import moment from "moment";

const Home = () => {
  const initialState = {
    userID: JSON.parse(localStorage.getItem("userID")),
  };
  const uid = parseInt(initialState.userID);

  const transactionContext = useContext(TransactionContext);
  const {
    transactions,
    //getLastTransactions,
    getLastTransactionsUid,
    getTransactionTypes,
    getCategories,
    getContexts,
    setCurrent,
    loading,
    setIsLoading,
  } = transactionContext;


  useEffect(() => {
    //getLastTransactions();
    getLastTransactionsUid(uid);
    getTransactionTypes();
    getContexts();
    getCategories();
    setIsLoading(false)
  }, [loading]);

  if (transactions !== null && transactions.length === 0 && !loading) {
    return <h4>Please add a transaction</h4>;
  }
  return (
    <Fragment>
      {transactions !== null && !loading ? (
        <div className="list-group">
          {transactions.map((t) => (
            <Link
              key={t.id}
              className="list-group-item list-group-item-action"
              to={`/edit`}
              onClick={() => setCurrent(t)}
            >
              {moment(t.date).format("yyyy-MM-DDTHH:mm")} | {t.title}
            </Link>
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};
export default Home;
