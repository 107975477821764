import React, { Fragment, useState, useContext, useEffect } from "react";
import TransactionContext from "../../context/transaction/transactionContext";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import Spinner from "../layout/Spinner";
//import dayjs, { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from "@mui/material/TextField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dropdown, Option } from "../form-components/Dropdown";
import { useNavigate } from "react-router-dom";


const TransactionForm = () => {
  const authContext = useContext(AuthContext);
  const transactionContext = useContext(TransactionContext);
  const alertContext = useContext(AlertContext);
  //const dateInputTextField = useRef();

  const {
    addTransaction,
    updateTransaction,
    clearCurrent,
    current,
    transactionTypes,
    contexts,
    categories,
    loading,
    setIsLoading,
    loadingType,
    loadingCategory,
    loadingContext,
  } = transactionContext;

  const { setAlert } = alertContext;

  const [dtpDate, setDtpDate] = useState(dayjs(new Date()));
  const [transaction, setTransaction] = useState({
    id: "0",
    date: dayjs(new Date()),
    type: "",
    title: "",
    description: "",
    amount: "",
    context: "",
    category: "",
    uid: "",
  });
  // extract userid from the authentication context
  const { user_id } = authContext;

  const [errors, setErrors] = useState({ title: "", description: "" });
  let history = useNavigate();

  const { date, type, title, description, amount, context, category } =
    transaction;

  useEffect(() => {
    if (current !== null) {
      setTransaction(current);
      setDtpDate(current.date)
    } else {
      setTransaction({
        id: "0",
        date: dtpDate,
        type: "",
        title: "",
        description: "",
        amount: "",
        context: "1",
        category: "1",
        uid: "",
      });
    }
  }, []);

  useEffect(() => {
    console.log("parsed transaction date: ", date)
  }, [transaction])

  // const onChange = (e) => {
  // //const onChange = (identifier, e) => {
  //   e.preventDefault();
  //   let errors = {
  //     date: "",
  //     type: "",
  //     title: "",
  //     description: "",
  //     amount: "",
  //     context: "",
  //     category: "",
  //   };
  //   if (!date) {
  //     errors.date = "Date is required";
  //   }

  //   if (!type) {
  //     errors.type = "Type is required";
  //   }

  //   if (!title) {
  //     errors.title = "Title is required";
  //   }

  //   if (!description) {
  //     errors.description = "Description is required";
  //   }

  //   if (!amount) {
  //     errors.description = "Amount is required";
  //   }

  //   if (!context) {
  //     errors.context = "Context is required";
  //   }

  //   if (!category) {
  //     errors.category = "Category is required";
  //   }
  //   console.log("[TransactionForm] ", e.target.name, " : ", e.target.value);
  //   // setTransaction(...prevValues => ({...prevValues, [identifier]: e.target.value}));
  //   // setErrors(errors);
  //   setTransaction({ ...transaction, [e.target.name]: e.target.value });
  //   setErrors(errors);

  // };

  const handleInputChange = (identifier, event) => {
    event.preventDefault();
    console.log("[TransactionForm] ", identifier, " : ", event.target.value);
    setTransaction(prevValue => ({ ...prevValue, [identifier]: event.target.value }));
    setErrors(errors);
  };

  const handleDate = (newDate) => {
    console.log("new date: ", newDate)
    //setDtpDate(moment(inputDate).format("yyyy-MM-DDTHH:mm"))
    setDtpDate(newDate)
    setTransaction(prevValue => ({ ...prevValue, "date": newDate }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // set date as original even if the use doesn't change the date-local field
    transaction.user_id = user_id;
    if (date === "" || title === "" || description === "") {
      setAlert("Please fill in all fields", "danger");
    } else {
      // set loading to true until the transaction is updated on the database
      setIsLoading(true)
      if (current === null) {
        addTransaction(transaction);
      } else {
        updateTransaction(transaction);
      }
      clearAll();
    }
  };

  const clearAll = () => {
    clearCurrent();
    // navigate programmatically to the main page
    history("/");
  };

  return (
    <Fragment>
      {transactionTypes !== null &&
        !loading &&
        !loadingType &&
        !loadingCategory &&
        !loadingContext ? (
        <form onSubmit={onSubmit}>
          <h2 className="text-primary">
            {current ? "Edit Transaction" : "Add Transaction"}
          </h2>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Transaction date and time"
              name="date"
              value={dtpDate}
              onChange={(newValue) => handleDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.date !== "" && (
              <span style={{ color: "red" }}>{errors.date}</span>
            )}
          </LocalizationProvider>

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Controlled picker"
              value={dtpDate}
              renderInput={(params) => <TextField {...params} />}
              onChange={(newValue) => setDtpDate(newValue)}
            />
          </LocalizationProvider> */}

          <Dropdown
            formLabel="Choose a type"
            name="type"
            //onChange={onChange}
            onChange={(event) => handleInputChange('type', event)}
            value={type}
          >
            {transactionTypes.map((option) => (
              <Option
                isSelected={option.id === type ? true : false}
                key={option.id}
                name="type"
                value={option.id}
                label={option.type}
              />
            ))}
          </Dropdown>

          {errors.type !== "" && (
            <span style={{ color: "red" }}>{errors.type}</span>
          )}
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={title}
            //onChange={onChange}
            onChange={(event) => handleInputChange('title', event)}
          />
          {errors.title !== "" && (
            <span style={{ color: "red" }}>{errors.title}</span>
          )}
          <textarea
            className="form-control"
            placeholder="Description"
            name="description"
            value={description}
            rows="10"
            //onChange={onChange}
            onChange={(event) => handleInputChange('description', event)}
          />
          {errors.description !== "" && (
            <span style={{ color: "red" }}>{errors.description}</span>
          )}
          <input
            type="text"
            placeholder="Amount"
            name="amount"
            value={amount}
            //onChange={onChange}
            onChange={(event) => handleInputChange('amount', event)}
          />
          {errors.amount !== "" && (
            <span style={{ color: "red" }}>{errors.amount}</span>
          )}
          <Dropdown
            formLabel="Choose a context"
            name="context"
            //onChange={onChange}
            onChange={(event) => handleInputChange('context', event)}
            value={context}
          >
            {contexts.map((option) => (
              <Option
                isSelected={option.id === context ? true : false}
                key={option.id}
                name="context"
                value={option.id}
                label={option.name}
              />
            ))}
          </Dropdown>
          {errors.context !== "" && (
            <span style={{ color: "red" }}>{errors.context}</span>
          )}
          <Dropdown
            formLabel="Choose a category"
            name="category"
            //onChange={onChange}
            onChange={(event) => handleInputChange('category', event)}
            value={category}
          >
            {categories.map((option) => (
              <Option
                isSelected={option.id === category ? true : false}
                key={option.id}
                name="category"
                value={option.id}
                label={option.name}
              />
            ))}
          </Dropdown>
          {errors.category !== "" && (
            <span style={{ color: "red" }}>{errors.category}</span>
          )}
          <div>
            <input
              type="submit"
              value={current ? "Update Transaction" : "Add Transaction"}
              className="btn btn-primary btn-block"
            />
          </div>
          {current && (
            <div>
              <button className="btn btn-light btn-block" onClick={clearAll}>
                Clear
              </button>
            </div>
          )}
        </form>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default TransactionForm;
