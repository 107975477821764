import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import TransactionContext from "../../context/transaction/transactionContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const TransactionItem = ({ transaction, props }) => {
  const transactionContext = useContext(TransactionContext);
  const {
    deleteTransaction,
    setCurrent,
    clearCurrent,
    transactionTypes,
    categories,
    contexts,
    loadingCategories,
    loadingContexts,
  } = transactionContext;

  const { id, date, type, title, description, amount, context, category } =
    transaction;
  let history = useNavigate();

  const onDelete = () => {
    deleteTransaction(id);
    clearCurrent();
  };

  const onEdit = (transaction) => {
    setCurrent(transaction);
    //console.log("[TransactionForm] onEdit: ", transaction.description);
    history("/edit");
  };

  const lookupType = (t) => {
    if (t !== undefined) {
      let obj = transactionTypes.find((o) => o.id === parseInt(t)).type;
      return obj;
    } else {
      console.log("[TransactionItem] type is: ", t);
    }
  };

  const lookupCategory = (c) => {
    if (c !== undefined) {
      let obj = categories.find((o) => o.id === parseInt(c)).name;
      return obj;
    } else {
      console.log("[TransactionItem] type is: ", c);
    }
  };

  const lookupContext = (c) => {
    if (c !== undefined) {
      let obj = contexts.find((o) => o.id === parseInt(c)).name;
      return obj;
    } else {
      console.log("[TransactionItem] type is: ", c);
    }
  };

  // const showType = () => {
  //   const ot = cardtypes.filter((obj) => {
  //     return type.includes(obj.id);
  //   });
  //   if (ot.length !== 0) {
  //     return ot[0].value.charAt(0).toUpperCase() + ot[0].value.slice(1);
  //   } else {
  //     return type.charAt(0).toUpperCase() + type.slice(1);
  //   }
  // };

  // const ct = showType();

  return (
    <Fragment>
      {categories !== null &&
      contexts !== null &&
      !loadingCategories &&
      !loadingContexts ? (
        <div className="card bg-light">
          <h3 className="text-primary text-left">
            {title}
            <span
              style={{ float: "right" }}
              className={
                "badge " + (type === "1" ? "badge-success" : "badge-primary")
              }
            >
              {lookupType(type)}
            </span>
          </h3>
          <ul className="list">
            {date && (
              <li>
                <i className="fas fa-regular fa-calendar" />{" "}
                {moment(date).format("YYYY-MM-DDTHH:mm")}
                {/* {date.toLocaleString("en-US", { timeZone: "America/New_York" })} */}
              </li>
            )}
            {description && (
              <li>
                <i className="fas fa-regular fa-file" /> {description}
              </li>
            )}
            {amount && (
              <li>
                <i className="fas fa-solid fa-coins" /> {amount} CHF
              </li>
            )}
            {context && (
              <li>
                <i className="fas fa-solid fa-chart-pie" />{" "}
                {lookupContext(context)}
              </li>
            )}
            {category && (
              <li>
                <i className="fas fa-solid fa-book" />{" "}
                {lookupCategory(category)}
              </li>
            )}
          </ul>
          <p>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => onEdit(transaction)}
            >
              Edit
            </button>
            <button className="btn btn-danger btn-sm" onClick={onDelete}>
              Delete
            </button>
          </p>
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

TransactionItem.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionItem;
