import React from "react";
import { StyledSelect, StyledOption } from "../../styles.js";

export function Dropdown(props) {
  return (
    <StyledSelect
      id={props.name}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    >
      {props.children}
    </StyledSelect>
  );
}

export function Option(props) {
  return <StyledOption value={props.value}>{props.label}</StyledOption>;
}
