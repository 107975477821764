import React, { Fragment, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import TransactionContext from "../../context/transaction/transactionContext";

const Navbar = ({ title, icon }) => {
  const authContext = useContext(AuthContext);
  const transactionContext = useContext(TransactionContext);

  const { isAuthenticated, logout, username } = authContext;
  const { clearTransactions } = transactionContext;

  useEffect(() => {
    //loadUser();
    // eslint-disable-next-line
  }, []);

  const onLogout = () => {
    logout();
    clearTransactions();
  };

  const authLinks = (
    <Fragment>
      <li>Hello {username}</li>
      <li>
        <Link to="/admin">Admin</Link>
      </li>
      <li>
        <a onClick={onLogout} href="#!">
          <i className="fas fa-sign-out-alt" />{" "}
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </Fragment>
  );

  return (
    <div className="navbar bg-primary">
      <h1>
        <Link to="/">
          <i className={icon} /> {title}
        </Link>
      </h1>
      <ul>{isAuthenticated ? authLinks : guestLinks}</ul>
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "Personal Finance Tracker",
  icon: "fas fa-id-card-alt",
};

export default Navbar;
