import React from "react";
import Transactions from "../transaction/Transactions";
import TransactionForm from "../transaction/TransactionForm";
import TransactionFilter from "../transaction/TransactionFilter";

const Admin = () => {
  return (
    <div className="grid-2">
      <div>
        <TransactionForm />
      </div>
      <div>
        <TransactionFilter />
        <Transactions />
      </div>
    </div>
  );
};

export default Admin;
