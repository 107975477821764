import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => (
  <nav>
    <Link to="/transactions">Expenses</Link>
    <br />
    <Link to="/new-expense">New Expense</Link>
    <br />
    <Link to="/new-category">New Categories</Link>
    <br />
    <Link to="/admin">Admin</Link>
  </nav>
);

const LatNavBar = () => {
  return <Navigation />;
};
export default LatNavBar;
