import {
  GET_TRANSACTIONS,
  GET_LAST_TRANSACTIONS,
  GET_LAST_TRANSACTIONS_UID,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_TRANSACTION,
  FILTER_MEDITATIONS,
  CLEAR_FILTER,
  CLEAR_MEDITATIONS,
  GET_TRANSACTION_TYPES,
  GET_CONTEXTS,
  GET_CATEGORIES,
  SET_IS_LOADING,
  TRANSACTION_ERROR,
} from "../types";

const transactionReducer = (state, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };
    case GET_LAST_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };
    case GET_LAST_TRANSACTIONS_UID:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };
    case GET_TRANSACTION_TYPES:
      return {
        ...state,
        transactionTypes: action.payload,
        loadingType: false,
      };
    case GET_CONTEXTS:
      return {
        ...state,
        contexts: action.payload,
        loadingContext: false,
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loadingCategory: false,
      };

    case ADD_TRANSACTION:
      return {
        ...state,
        transactions: [action.payload, ...state.transactions],
        loading: false,
      };
    case UPDATE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.map((transaction) =>
          transaction.id === action.payload._id ? action.payload : transaction
        ),
        loading: false,
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        transactions: state.transactions.filter(
          (transaction) => transaction.id !== action.payload
        ),
        loading: false,
      };
    case CLEAR_MEDITATIONS:
      return {
        ...state,
        meditations: null,
        filtered: null,
        error: null,
        current: null,
      };
    case SET_CURRENT:
      // console.log(
      //   "[SET_CURRENT] Payload description: ",
      //   action.payload.description
      // );
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case FILTER_MEDITATIONS:
      return {
        ...state,
        filtered: state.meditations.filter((meditation) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            meditation.title.match(regex) || meditation.description.match(regex)
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case TRANSACTION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case SET_IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
export default transactionReducer;
