import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import LatNavBar from "./components/layout/LatNavbar";
import Home from "./components/pages/Home";
import TransactionForm from "./components/transaction/TransactionForm";
import Transactions from "./components/transaction/Transactions";
import Admin from "./components/pages/Admin";
import About from "./components/pages/About";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Alerts from "./components/layout/Alerts";
import ProtectedRoute from "./components/routing/ProtectedRoute";

import TransactionState from "./context/transaction/TransactionState";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import "./App.css";

const App = () => {
  return (
    <AuthState>
      <TransactionState>
        <AlertState>
          <BrowserRouter>
            <div className="container">
              <Navbar />
              <div className="row">
                <div className="col-md-2">
                  <Alerts />
                  <LatNavBar />
                </div>
                <div className="col-md-10">
                  <Routes>
                    <Route exact path="/" element={<Home />} />

                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />

                    <Route
                      path="/*"
                      element={
                        <ProtectedRoute redirectPath="/login">
                          <Routes>
                            <Route
                              path="/transactions"
                              element={<Transactions />}
                            ></Route>
                            <Route
                              path="/edit"
                              element={<TransactionForm />}
                            ></Route>
                            <Route
                              path="/new-expense"
                              element={<TransactionForm />}
                            ></Route>
                            <Route path="/admin" element={<Admin />}></Route>
                          </Routes>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/*"
                      element={
                        <ProtectedRoute redirectPath="/login"></ProtectedRoute>
                      }
                    />
                    <Route path="/about" element={<About />} />
                    <Route
                      path="*"
                      element={<p> There is nothing here: 404!</p>}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        </AlertState>
      </TransactionState>
    </AuthState>
  );
};

export default App;
