import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };

    case LOGIN_SUCCESS:
      console.log(
        "[AuhReducer] response user_id: ",
        JSON.stringify(action.payload.credentials.user_id)
      );
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.credentials.token)
      );
      localStorage.setItem(
        "userID",
        JSON.stringify(action.payload.credentials.user_id)
      );
      localStorage.setItem(
        "userName",
        JSON.stringify(action.payload.credentials.username)
      );
      return {
        ...state,
        ...action.payload.credentials,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
      console.log(
        "[AuhReducer] response error: ",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("username");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: JSON.stringify(action.payload),
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export default authReducer;
