import React, { Fragment, useContext, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TransactionItem from "../transaction/TransactionItem";
import Spinner from "../layout/Spinner";
import TransactionContext from "../../context/transaction/transactionContext";

const Transactions = () => {
  const transactionContext = useContext(TransactionContext);

  const initialState = {
    userID: JSON.parse(localStorage.getItem("userID")),
  };
  const uid = parseInt(initialState.userID);

  const {
    transactions,
    filtered,
    //getLastTransactions,
    getLastTransactionsUid,
    getTransactionTypes,
    getCategories,
    getContexts,
    loading,
  } = transactionContext;

  useEffect(() => {
    getLastTransactionsUid(uid);
    getTransactionTypes();
    getContexts();
    getCategories();
    // eslint-disable-next-line
  }, []);

  if (transactions !== null && transactions.length === 0 && !loading) {
    return <h4>Please add a transaction</h4>;
  }

  return (
    <Fragment>
      {transactions !== null && !loading ? (
        <TransitionGroup>
          {filtered !== null
            ? filtered.map((transaction) => (
                <CSSTransition
                  key={transaction.id}
                  timeout={500}
                  classNames="item"
                >
                  <TransactionItem transaction={transaction} />
                </CSSTransition>
              ))
            : transactions.map((transaction) => (
                <CSSTransition
                  key={transaction.id}
                  timeout={500}
                  classNames="item"
                >
                  <TransactionItem transaction={transaction} />
                </CSSTransition>
              ))}
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};
export default Transactions;
