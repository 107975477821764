import React, { useContext, useRef, useEffect } from "react";
import TransactionContext from "../../context/transaction/transactionContext";

const TransactionFilter = () => {
  const transactionContext = useContext(TransactionContext);
  const text = useRef("");

  const { filterTransactions, clearFilter, filtered } = transactionContext;

  useEffect(() => {
    if (filtered === null) {
      text.current.title = "";
    }
  });

  const onChange = (e) => {
    if (text.current.title !== "") {
      console.log(
        "[TransactionFilter] current title text: ",
        text.current.title
      );
      filterTransactions(e.target.value);
    } else {
      clearFilter();
    }
  };

  return (
    <form>
      <input
        ref={text}
        type="text"
        placeholder="Filter Transactions..."
        onChange={onChange}
      />
    </form>
  );
};

export default TransactionFilter;
