import React, { useReducer } from "react";
import axios from "axios";
import TransactionContext from "./transactionContext";
import transactionReducer from "./transactionReducer";
import {
  GET_TRANSACTIONS,
  GET_LAST_TRANSACTIONS,
  GET_LAST_TRANSACTIONS_UID,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_TRANSACTION,
  FILTER_MEDITATIONS,
  CLEAR_TRANSACTIONS,
  CLEAR_FILTER,
  TRANSACTION_ERROR,
  GET_TRANSACTION_TYPES,
  GET_CONTEXTS,
  GET_CATEGORIES,
  SET_IS_LOADING,
} from "../types";

// Set a base url from the environment variables
const baseURL = `${process.env.REACT_APP_API_URL}`;

const TransactionState = (props) => {
  const initialState = {
    transactions: null,
    lastTransactions: null,
    transactionType: null,
    categories: null,
    random: null,
    current: null,
    filtered: null,
    error: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(transactionReducer, initialState);

  // Get all transactions
  const getTransactions = async () => {
    try {
      const res = await axios.get(baseURL + `/api/transactions`);
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getLastTransactions = async () => {
    try {
      const res = await axios.get(baseURL + `/v1/lasttransactions`);
      dispatch({
        type: GET_LAST_TRANSACTIONS,
        payload: res.data.lasttransactions,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getLastTransactionsUid = async (uid) => {
    try {
      const res = await axios.post(baseURL + `/v1/lasttransactionsuid/${uid}`);
      dispatch({
        type: GET_LAST_TRANSACTIONS_UID,
        payload: res.data.lasttransactionsuid,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Add Transaction
  const addTransaction = async (transaction) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(
        baseURL + `/v1/admin/edittransaction/${transaction.id}`,
        transaction,
        config
      );
      console.log("[state addTransaction] add transaction: ", transaction);

      dispatch({
        type: ADD_TRANSACTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Delete Transaction
  const deleteTransaction = async (id) => {
    try {
      await axios.post(baseURL + `/v1/admin/deletetransaction/${id}`);

      dispatch({
        type: DELETE_TRANSACTION,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Update Transaction
  const updateTransaction = async (transaction) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log('[updateTransaction]: ', transaction);
      const res = await axios.post(
        baseURL + `/v1/admin/edittransaction/${transaction.id}`,
        transaction,
        config
      );

      dispatch({
        type: UPDATE_TRANSACTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Clear Transactions
  const clearTransactions = () => {
    dispatch({ type: CLEAR_TRANSACTIONS });
  };

  // Set Current Transactions
  const setCurrent = (transaction) => {
    dispatch({ type: SET_CURRENT, payload: transaction });
  };

  // Clear Current Transactions
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  const setIsLoading = (status) => {
    console.log("[SET_IS_LOADING], Loading status:", status)
    dispatch({ type: SET_IS_LOADING, payload: status });
  }
  // Filter Meditation
  const filterMeditations = (text) => {
    dispatch({ type: FILTER_MEDITATIONS, payload: text });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  // // Get transaction types
  // const getTransactionTypes = async () => {
  //   try {
  //     const res = await axios.get("/v1/transactions");
  //     var tyo = [];
  //     for (var i in res.data)
  //       tyo.push({ id: res.data[i]._id, value: res.data[i].type });
  //     //console.log('[getCardTypes] tyo: ', tyo);
  //     dispatch({
  //       type: GET_TRANSACTION_TYPES,
  //       payload: tyo,
  //     });
  //   } catch (err) {
  //     dispatch({
  //       type: TRANSACTION_ERROR,
  //       payload: err.response.msg,
  //     });
  //   }
  // };

  // Get contexts with graphQL call
  const getTransactionTypes = async () => {
    try {
      const payload = `
      {
        transactionTypes {
          id
          type
          description
        }
      }
      `;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const res = await axios.post(
        baseURL + `/v1/graphql/transactionTypes`,
        payload,
        {
          headers: myHeaders,
        }
      );
      dispatch({
        type: GET_TRANSACTION_TYPES,
        payload: res.data.data.transactionTypes,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Get contexts with graphQL call
  const getContexts = async () => {
    try {
      const payload = `
      {
        contexts {
          id
          name
          description
        }
      }
      `;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const res = await axios.post(baseURL + `/v1/graphql/contexts`, payload, {
        headers: myHeaders,
      });
      dispatch({
        type: GET_CONTEXTS,
        payload: res.data.data.contexts,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // Get categories with graphQL call
  const getCategories = async () => {
    try {
      const payload = `
      {
        categories {
          id
          name
          description
        }
      }
      `;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const res = await axios.post(
        baseURL + `/v1/graphql/categories`,
        payload,
        {
          headers: myHeaders,
        }
      );
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data.data.categories,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  return (
    <TransactionContext.Provider
      value={{
        transactions: state.transactions,
        lastTransactions: state.lastTransactions,
        random: state.random,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        cardtypes: state.cardtypes,
        transactionTypes: state.transactionTypes,
        contexts: state.contexts,
        categories: state.categories,
        loading: state.loading,
        loadingCategories: state.loadingCategories,
        loadingContexts: state.loadingContexts,
        addTransaction,
        deleteTransaction,
        setCurrent,
        clearCurrent,
        setIsLoading,
        updateTransaction,
        filterMeditations,
        clearFilter,
        getTransactions,
        getLastTransactions,
        getLastTransactionsUid,
        clearTransactions,
        getTransactionTypes,
        getContexts,
        getCategories,
      }}
    >
      {props.children}
    </TransactionContext.Provider>
  );
};

export default TransactionState;
