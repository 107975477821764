import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const ProtectedRoute = ({ redirectPath, children }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  return isAuthenticated ? children : <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
